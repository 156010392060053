<template>
  <base-modal-view title="Create Tender" @cancel="cancelClicked">
    <p class="body-2 no-margin margin-top" v-if="!tender && !forCSVUpload">Appraisal Count: {{ formCount }}</p>
    <p class="no-margin margin-top label">Title</p>
    <input v-model="title" type="text" class="margin-top-025 text-field" placeholder="Tender Name" />
    <p class="no-margin margin-top-05 label" v-if="forCSVUpload">File:</p>
    <input
      v-if="forCSVUpload"
      accept=".csv"
      type="file"
      class="margin-top-025 text-field"
      id="file"
      ref="file"
      v-on:change="handleFileUpload()"
    />
    <p class="no-margin margin-top-05 label">Start Time</p>
    <input class="margin-top-025 text-field" type="datetime-local" v-model="startTime" />
    <p class="no-margin margin-top-05 label">End Time</p>
    <input class="margin-top-025 text-field" type="datetime-local" v-model="endTime" />

    <p class="no-margin margin-left-auto body-2-bold margin-top-025 error" v-if="inputError()">{{ inputError() }}</p>

    <primary-button
      :disabled="inputError()"
      v-on:click="submitClicked"
      class="margin-top-1-05"
      :title="tender ? 'Update Tender' : 'Create Tender'"
    />
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import moment from "moment";
import { fetchPendingFormsCount } from "../../../api/Form.js";

export default {
  name: "CreateTenderModal",
  components: { PrimaryButton, BaseModalView },
  props: ["tender", "forCSVUpload"],
  data: function () {
    return {
      title: "",
      startTime: moment(new Date()).seconds(0).milliseconds(0).format("yyyy-MM-DDTHH:mm"),
      endTime: moment(new Date()).seconds(0).milliseconds(0).format("yyyy-MM-DDTHH:mm"),
      formCount: null,
      file: null
    };
  },
  methods: {
    submitClicked: function () {
      this.$emit("submit", {
        title: this.title,
        start_time: new Date(this.startTime).toISOString(),
        end_time: new Date(this.endTime).toISOString(),
        file: this.file
      });
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    inputError: function () {
      let startTime = new Date(this.startTime);
      let endTime = new Date(this.endTime);
      if (this.forCSVUpload && !this.file) {
        return "A file is required.";
      }

      if (startTime && endTime) {
        if (startTime < new Date()) {
          return "The start time must be later than now.";
        } else if (endTime < new Date()) {
          return "The end time must be later than now";
        } else if (endTime < startTime) {
          return "The end time must be after the start time";
        } else {
          return null;
        }
      } else {
        return "Tenders require a start and end time.";
      }
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0];
    }
  },
  mounted: function () {
    if (this.tender) {
      this.title = this.tender.title;
      this.startTime = moment(new Date(this.tender.start_time)).format("yyyy-MM-DDTHH:mm");
      this.endTime = moment(new Date(this.tender.end_time)).format("yyyy-MM-DDTHH:mm");
    } else {
      fetchPendingFormsCount().then(response => {
        this.formCount = response.count;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
