<template>
  <transition name="fade" mode="out-in">
    <div ref="modalbg" class="flex-column modal-background" v-if="!hidden">
      <div
        id="content"
        :style="{ width: autoWidth ? 'auto' : wide ? '95%' : '520px' }"
        class="shadow flex-column padding-2"
        style="overflow: auto"
      >
        <div class="flex-row">
          <h4 class="h4 vertical-flex-center margin-right-auto">{{ title }}</h4>
          <button v-on:click="cancelClicked()" class="no-button-style vertical-flex-center pointer-cursor">
            <img src="/assets/img/icon-close.svg" />
          </button>
        </div>
        <p class="body-2 margin-top" v-if="message">
          {{ message }}
        </p>
        <slot> </slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BaseModalView",
  props: ["title", "message", "wide", "autoWidth"],
  data: function () {
    return {
      hidden: true
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  mounted() {
    let _this = this;
    setTimeout(function () {
      _this.$nextTick(function () {
        _this.hidden = false;
      });
    }, 10);
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.modal-background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

#content {
  max-height: 90%;
  margin: auto;
  display: flex;
  background-color: white;
  overflow: scroll;
  border-radius: 8px;

  @media only screen and (max-width: 500px) {
    max-width: 100%;
  }
}
</style>
